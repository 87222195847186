<template>
  <div>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="based_on"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="PL based on"
        >
          <b-form-input
            v-model="based_on"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="based_on"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-row>
    <SimpleTable v-bind="$attrs"></SimpleTable>
  </div>
</template>

<script>
import SimpleTable from '@/components/Table/Simple';
export default {
  name: 'PurchaseLimits',
  components: { SimpleTable },
  data() {
    return {
      based_on: 11
    };
  },

  methods: {
    init() {
      console.log(this.$route);
    }
  },

  computed: {
    // ...mapState({
    //   baseConfig: (state) => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
